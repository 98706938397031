import React, { useEffect, useState } from "react";
import "./HeroSection.css";

function HeroSection() {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
    }, []);

    return (
        <div className="hero-Section">
            <div className={`first-half ${animate ? 'slide-up appear' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div className="banner-text">
                    <h1 className="text1"><strong>WATCH,</strong></h1>
                    <h1 className="text2"><strong>LEARN,</strong></h1>
                    <h1 className="text3"><strong>GROW.</strong></h1>
                </div>
                <div className="buttons">
                    <button className="btn">FREE DEMO</button>
                    <button className="btn">EXPLORE</button>
                </div>
            </div>
            <div className={`second-half ${animate ? 'slide-right appear' : ''}`}>
                <div className="card">
                    <div className="card1">
                        <img
                            src="https://images.pexels.com/photos/2381069/pexels-photo-2381069.jpeg?auto=compress&cs=tinysrgb&w=600.jpg"
                            alt="alternatetext"
                        />
                        <div className="overlay-text1">
                            <h2 className="h2-text"><strong>COURSE</strong></h2>
                        </div>
                        <div className="overlay-text2">
                            <h2 className="h2-text"><strong>Course</strong></h2>
                            <hr className="bannerhr" />
                            <h4 className="h4-text">10 Topics</h4>
                        </div>
                    </div>
                    <div className="card1">
                        <img
                            src="https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600.jpg"
                            alt="alternatetext"
                        />
                        <div className="overlay-text1">
                            <h2 className="h2-text"><strong>COURSE</strong></h2>
                        </div>
                        <div className="overlay-text2">
                            <h2 className="h2-text"><strong>Course</strong></h2>
                            <hr className="bannerhr" />
                            <h4 className="h4-text">10 Topics</h4>
                        </div>
                    </div>
                    <div className="card1">
                        <img
                            src="https://images.pexels.com/photos/3760514/pexels-photo-3760514.jpeg?auto=compress&cs=tinysrgb&w=600.jpg"
                            alt="alternatetext"
                        />
                        <div className="overlay-text1">
                            <h2 className="h2-text"><strong>COURSE</strong></h2>
                        </div>
                        <div className="overlay-text2">
                            <h2 className="h2-text"><strong>Course</strong></h2>
                            <hr className="bannerhr" />
                            <h4 className="h4-text">10 Topics</h4>
                        </div>
                    </div>
                    <div className="card1">
                        <img
                            src="https://images.pexels.com/photos/789822/pexels-photo-789822.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.jpg"
                            alt="alternatetext"
                        />
                        <div className="overlay-text1">
                            <h2 className="h2-text"><strong>COURSE</strong></h2>
                        </div>
                        <div className="overlay-text2">
                            <h2 className="h2-text"><strong>Course</strong></h2>
                            <hr className="bannerhr" />
                            <h4 className="h4-text">10 Topics</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
