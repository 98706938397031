import logo from './logo.svg';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react'
import './App.css';
import HomePage from './pages/HomePage/Homepage';

function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
          <Route exact path='/' element={<HomePage />} />
          </Routes>

      </Router>
     
    </div>
  );
}

export default App;
