import React, { useRef, useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { closeOutline, menuOutline } from 'ionicons/icons';
import logo from '../../assets/images/logo-main.png';

function Navbar() {
    const [isActive, setIsActive] = useState(true);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navbarRef = useRef(null);

    const toggleDropdown = (dropdownName) => {
        setActiveDropdown((prevDropdown) => (prevDropdown === dropdownName ? null : dropdownName));
    };

    const closeAllMenus = () => {
        setActiveDropdown(null);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            closeAllMenus();
        }
    };

    const handleScroll = () => {
        const heroHeight = document.querySelector('.hero-Section').offsetHeight;
        if (window.scrollY === 0) {
            setIsActive(true);
        } else if (window.scrollY >= heroHeight) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleNavbar = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeNavbar = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <div className={`navbar-container ${isActive ? 'visible' : 'hidden'}`} ref={navbarRef}>
            <nav className={`navbar ${isActive ? "active" : ""}`} data-navbar ref={dropdownRef}>
                <div className="logo">
                    <img src={logo} width="130" height="55" alt="logo" />
                </div>
                <div className={`navbar-list ${isMobileMenuOpen ? 'open' : ''}`}>
                    <ul>
                        <li>Home</li>
                        <li className="navbar-item dropdown">
                            <button className="navbar-link" onClick={() => toggleDropdown('resources')}>
                                Categories<i className="ri-arrow-down-s-line dropdown__arrow"></i>
                            </button>
                            <ul className={`dropdown-menu ${activeDropdown === 'resources' ? 'open' : ''}`}>
                                <li><Link className="navbar-link" onClick={closeAllMenus} data-nav-link to="">Course-1</Link></li>
                                <hr />
                                <li><Link className="navbar-link" onClick={closeAllMenus} data-nav-link to="">Course-2</Link></li>
                                <hr />
                                <li><Link className="navbar-link" onClick={closeAllMenus} data-nav-link to="">Course-3</Link></li>
                                <hr />
                                <li><Link className="navbar-link" onClick={closeAllMenus} data-nav-link to="">Mini Courses</Link></li>
                            </ul>
                        </li>
                        <li>Testimonials</li>
                        <li>About</li>
                    </ul>
                    {isMobileMenuOpen && (
                        <button className="close-menu" aria-label="close menu" onClick={closeNavbar}>
                            <IonIcon icon={closeOutline} aria-hidden="true" />
                        </button>
                    )}
                </div>
                <div className="login-buttons">
                    <button className='btn-login'>Login</button>
                    <button className='btn-demo'>Book Demo</button>
                    <button className="hamburger-menu" aria-label="open menu" onClick={toggleNavbar} data-nav-toggler>
                        <IonIcon icon={menuOutline} aria-hidden="true" />
                    </button>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
